<template>
	<div id="login" class="mt-10">
		<v-form
			ref="form"
			v-model="form.valid"
			lazy-validation
		>
			<v-container>
				<v-row class="pt-10">
					<v-col sm="12" align="center">
						<h1>Forgot Password?</h1>
						<p>Please enter your account email below and click the reset password link.  You will receive an email with a reset password action.  <br>If you do not receive the email please check your junk or spam folder.</p>
					</v-col>
				</v-row>
				<v-row>
					<v-col sm="6" offset-sm="3">
						<v-row dense>
							<v-col>
								<v-text-field
									filled
									label="Password"
									v-model="form.fields.password"
									required
									:rules="form.validation.passwordRules"
									type="password"
								></v-text-field>
							</v-col>
							<v-col>
								<v-text-field
									filled
									label="Re-type Password"
									v-model="form.fields.password_confirmation"
									required
									:rules="form.validation.confirmRules"
									type="password"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col sm="12" align="right">
								<v-btn color="deep-orange darken-3" dark x-large @click.prevent="reset">
									Reset
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</div>
</template>

<script>
	import ApiService from '@/services/ApiService.js';

	export default {
		name: "NewPassword.vue",
		data() {
			return {
				form: {
					valid: true,
					validation:{
						passwordRules: [
							v => !!v || 'Password is required',
						],
            /* eslint-disable no-unused-vars */
						confirmRules: [
							v => !!v || 'Please confirm your password',
							v => this.form.fields.password === this.form.fields.password_confirmation || 'Passwords must match',
						],
					},
					fields:{
						id: null,
						password: null,
						password_confirmation: null,
						token: null
					}
				},
			};
		},
		mounted() {
			this.form.fields.id = this.$route.query.user;
			this.form.fields.token = this.$route.query.token;
		},
		computed: {},
		methods: {
			reset() {
				let self = this;
				if(this.$refs.form.validate()){
					ApiService
						.post(`/account/reset-password/${this.form.fields.id}/${this.form.fields.token}`, this.form.fields)
						.then(() => {
							self.$root.snackbar.message = 'Password reset successfully!';
							self.$root.snackbar.open = true;
							self.$router.replace('/login')
						})
						.catch((err) => {
							self.$root.snackbar.message = err.message;
							self.$root.snackbar.open = true;
						})
						.finally(() => (this.loading = false));
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	h1 {
		line-height: 1.2em;
	}
	textarea {
		height: 13rem;
	}
	.map {
		border-radius: 10px;
		overflow: hidden;
		border: solid 1px #cfd8dc;
	}
</style>
